import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 as uuidv4 } from 'uuid';

const RowTitle =styled.h2`
  font-size: 25px;
  margin: 0px 0px;
  padding-bottom: 20px;
`

const VoiceLinkWrapper = styled(Link)`
  text-decoration: none;
`

const RowSubTitle =styled.h3`
  font-size: 12px;
  font-family: 'Poppins';
  margin: 0px 0px;
`

const AvatarWrapper = styled.div` 
  width:80px;
  height:80px;
  border-radius:50%;
  -webkit-mask-image: -webkit-radial-gradient(white, black); // fix safari overflow bug
  border:4px solid ${props => props.theme.secondaryColor};
  background-color: white;
  overflow: hidden;
  margin: auto;
`

const Name = styled.h3`
  text-align: center;
  max-width:90px;
  margin: 10px 0;
  word-break: break-word;
`


const VoicesRow = ({voices}) => {

  
  return (
    <>
      <RowSubTitle>New</RowSubTitle>
      <RowTitle>Community Creators</RowTitle>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={20}
          freeMode={true}
          style={{overflow: 'visible'}}
        >
          {voices
          .sort ( (voice1, voice2) => new Date(voice2.node.dateCreated) - new Date(voice1.node.dateCreated)) 
          .map(voice =>(
            <SwiperSlide  style={{width: "auto"}} key={uuidv4()}>
              <VoiceLinkWrapper to={`/community/voice/${voice.node.slug}`}>
                <AvatarWrapper>
                  <GatsbyImage image={voice.node.localImage.childImageSharp.gatsbyImageData} alt={`Voice avatar`}/>
                </AvatarWrapper>
                <Name>
                  {voice.node.name}
                </Name>
              </VoiceLinkWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
    </>
  )
}

export default VoicesRow