import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import spiciness1 from "@images/spiciness-1.png";
import spiciness2 from "@images/spiciness-2.png";
import spiciness3 from "@images/spiciness-3.png";
import lockOverlay from "@images/lock-overlay.svg" 
import adOg from "@images/logos/ad-original-logo.png" 

const SliderImageWrapper =  styled.div`
  display: block;
  border-radius:5px;
  transform: translate3d(0,0,0) translateZ(1000px); // fixes safari transform bug
`

const CoverImage = styled(GatsbyImage)`
  border-radius: 5px;
  margin:5px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

const StorySliderLink = styled(Link)`
  border-radius:5px;
  display:block;
  text-decoration: none;
  color:#000000;
  background-color:white;
`

const StorySliderItemMeta = styled.div`
  margin:0 10px;
  position:relative;
  height:100px;
  @media (max-width: 320px) {
    height:120px;
  }
`

const OverlayLock = styled.img`
  box-sizing: border-box;
  margin:5px;
  border-radius:5px;
  z-index:15;
  position:absolute;
  top:0px;
  width:calc(100% - 10px);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  transform: translate3d(0,0,0) translateZ(1010px); // fixes safari transform bug see: https://discourse.webflow.com/t/safari-not-hiding-overflow-on-rounded-corner-divs/55060
`

const OriginalContentImg = styled.img`
  z-index: 10;
  height: 15px;
  width: 15px;
  top: 15px;
  position: absolute;
  left: 15px;
  transform: translate3d(0,0,0) translateZ(1000px); // fixes safari transform bug
`

const StoryTitle =  styled.h3`
  margin: 0px 0px;
  font-size: 16px;
  font-family: 'Romana Becker Demi';
  display: block;
`

const StoryVoice = styled.h4`
  margin:0;
  font-size: 12px;
  font-family: 'Poppins';
  color: #707070;
  font-weight: 500;
`

const BottomMetaWrapper = styled.div`
  position:absolute;
  bottom:10px;
  width:100%;
  display:flex;
  justify-content:space-between;
  align-items: center;
`

const SpicinessWrapper = styled.div`

 img {
   width:60px;
 }

`

const Duration = styled.h5`
  font-family: 'Poppins';
  margin:0;

  right:20px;
`

const StorySliderItem = (props) => {

  const {
    spiciness,
    voices,
    title,
    coverImage,
    premiumContent,
    audiodesiresOriginal,
    link,
    duration,
    user,
    locks,
  } = props

return (
  
    <StorySliderLink to={link}>
      <SliderImageWrapper>
        {audiodesiresOriginal && <OriginalContentImg src ={adOg}></OriginalContentImg>}
        {(((premiumContent === "true" && user && !user.isSubscriber) || (premiumContent === "true" && !user )) && (locks) ) && 
          <OverlayLock src={lockOverlay} /> 
        }
        <CoverImage image={coverImage?.gatsbyImageData}  alt={`${title} Erotic Audio Story Audiodesires`}/> 
      </SliderImageWrapper>
      <StorySliderItemMeta>
        <StoryTitle >{title}</StoryTitle >
        <StoryVoice>
          {voices[0].name !== "placeholder" && 
            <>
              {voices[0].name}{`${voices[1] ? ` + ${voices[1].name}`:""}`}
            </>
          }
          </StoryVoice>
        <BottomMetaWrapper>
          <SpicinessWrapper>
            {spiciness === "1" && <img alt="least hot indicator" src={spiciness1}/> }
            {spiciness === "2" && <img alt="medium hot indicator" src ={spiciness2}/> }
            {spiciness === "3" && <img alt="most hot indicator" src ={spiciness3}/> }
          </SpicinessWrapper>
          <Duration>{`${parseInt(parseInt(duration)/60)} MINS`}</Duration>
        </BottomMetaWrapper>
      </StorySliderItemMeta>
    </StorySliderLink>
 
)
}

export default StorySliderItem