import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 as uuidv4 } from 'uuid';
import micImg from "@images/community/mic.png";
import cuffsImg from "../../images/community/cuffs.png";
import writerImg from "../../images/community/writer.png";
import audioImg from "../../images/community/walkman.png";


const roles = [
  {
    title:"Submit Your Fantasies", 
    text:"Premium users can submit fantasies to be turned into erotic audio stories.",
    button:"Submit now",
    link:"https://docs.google.com/forms/d/e/1FAIpQLSdaXEdoObvpB1stPeFrb3czHFyn0qA2yukVLU0ayRmor5dgrA/viewform",
    linktype:"external",
    premiumOnly: true,
    imageIndex:"0"
  },
  {
    title:"Submit Your Erotic Audio", 
    text:"Erotic audio artists can submit their own episodes to our Community section.",
    button:"Submit now",
    link:"https://audiodesires.com/voice-actor/community/",
    linktype:"internal",
    premiumOnly: false,
    imageIndex:"3"
  },
  {
    title:"Become a Voice Actor", 
    text:"Professional voice actors can apply to join our team of voice actors.",
    button:"Apply now",
    link:"https://docs.google.com/forms/d/e/1FAIpQLScN6h5vxbXP7XSFRr-d0DU4q52Ye_MHVrysjVx27wLp2kE9NQ/viewform?pli=1",
    linktype:"external",
    premiumOnly: false,
    imageIndex:"1"
  },
  {
    title:"Become a Writer", 
    text:"Professional writers can apply to join our writing team.",
    button:"Apply now",
    link:"https://docs.google.com/forms/d/e/1FAIpQLSeAlSFNQJOr-c8Doqd1eqIp5gcBcBpY1qBe1S593N-wTo2blQ/viewform",
    linktype:"external",
    premiumOnly: false,
    imageIndex:"2"
  }
]


const JoinUsWrapper = styled.div`
  z-index:1;
  height:180px;
  border-radius:8px;
  background-color:white;
  padding:20px 30px;
  position: relative;
  overflow: hidden;
  &:after{
    z-index:-1;
    background-image: ${props =>  `url(${icons[props.index]})`};
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    position: absolute;
    width: 110px;
    height: 110px;
    bottom: -20px;
    right: -10px;
    transform: rotate(-40deg);
  }
  h3{
    z-index:5;
    font-family: 'poppins';
    font-size: 14px;
    padding:0;
    margin:0;
  }
  h2{
    z-index:5;
    max-width: 100%;
    font-size:22px;
    margin:5px 0;
  }
`

const ApplyButton = styled.button`
  margin-top:20px;
  background:${props => props.inverted ? 'white' : props.theme.secondaryColor};
  border-radius: 20px;
  border:none;
  padding:10px 25px;
  color:white;
  text-transform: uppercase;
`

const icons = [cuffsImg, micImg, writerImg,audioImg]


const JoinUsSlider = ({onSetShowPremiumOnlyModal,user}) => {

  
  return(
    <Swiper
      slidesPerView={1}
      spaceBetween={20}
      freeMode={true}
      freeModeSticky={true}
      freeModeMomentumRatio={0.5}
      pagination={{ 
        clickable: true,
      }}
      style={{overflow: 'visible',marginTop:"30px",marginBottom:"30px"}}
      height="200px"
    >
      {roles.map((slide) =>(
        <SwiperSlide  style={{width: "auto",  }} key={uuidv4()}>
          <JoinUsWrapper index={slide.imageIndex}>
            <h2>{slide.title}</h2>
            <h3>{slide.text}</h3>
            {slide.premiumOnly && !user?.isSubscriber 
              ?  
                <ApplyButton onClick={() => onSetShowPremiumOnlyModal(true)}>{slide.button}</ApplyButton>
              :
                <a href={slide.link} target="_blank" rel="noreferrer"> <ApplyButton>{slide.button}</ApplyButton></a>
            }
          </JoinUsWrapper>
        </SwiperSlide>
        ))}
    </Swiper>
  )
}

export default JoinUsSlider

